<template>
<el-menu>
        <router-link to="/home" class="routerlink">
              <img src='../../public/logo.png' class="avatarimg"/>
            </router-link>
        <router-link to="/home" class="routerlink">
          <el-menu-item index="1">
            <i class="el-icon-location"></i>
            <span>主页</span>
          </el-menu-item>
        </router-link>
        <router-link to="/sitepasswd" class="routerlink">
          <el-menu-item index="2">
            <i class="el-icon-menu"></i>
            <span>密码列表</span>
          </el-menu-item>
        </router-link>
        <router-link to="/task" class="routerlink">
          <el-menu-item index="3">
            <i class="el-icon-document"></i>
            <span>任务列表</span>
          </el-menu-item>
        </router-link>
        <router-link to="/chat" class="routerlink">
          <el-menu-item index="4">
            <i class="el-icon-document"></i>
            <span>ChatGPT</span>
          </el-menu-item>
        </router-link>
      </el-menu>
</template>

<script>
export default {
name: "sideBar"
}
</script>

<style scoped>

.routerlink {
  color: black;
  text-decoration: none; /* 取消下划线 */

}
.el-menu {
  background-color: #faf9f5;
}
</style>